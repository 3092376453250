import { initializeApp } from 'firebase/app';
import { signInWithEmailAndPassword,getAuth,createUserWithEmailAndPassword } from 'firebase/auth';

//**** Firebase initailization  ***/
const firebaseConfig = {
  apiKey: "AIzaSyCqxt34AWlGnGj7zP4aJWxQbPVeGm_hLwE",
  authDomain: "cb-stay.firebaseapp.com",
  projectId: "cb-stay",
  storageBucket: "cb-stay.appspot.com",
  messagingSenderId: "8193628326",
  appId: "1:8193628326:web:33a534a3229d0e50d67c67",
  databaseURL:'https://cb-stay-default-rtdb.firebaseio.com/'
};
export const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
signInWithEmailAndPassword(getAuth(),'user1@knoxweb.com','sR123456').then((user)=> {
  console.log('firebase user',user);
},err=>{
  createUserWithEmailAndPassword(getAuth(),'user1@knoxweb.com','sR123456').then((user)=> {
    signInWithEmailAndPassword(getAuth(),'user1@knoxweb.com','sR123456');
  });
  console.log('User not registered')
})
